<template>
  <div class="AnimationSettingsMain">
    <div  style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Animation</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
        </div>
        </div>
        <div class="mt-2"  >
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Use Animation:</span>
        <b-form-checkbox
        :id="`animation-use-${Date.now()}`"
            v-model="animation.show"
                  ></b-form-checkbox>
      </label>
     
    </div>

    <div class="" :class="[animation.show?'':'progressbar-disabled']">
      <div class="w-100 mt-3">
        <div class="d-flex justify-content-between align-items-center">
         <label for="" class="editor-label">Speed:</label>
         <div class="select-per-page-count">
         <input v-model.number="animation.speed" type="number" min="0" max="5" class="" name="" id="">
         <span>Sec.</span>
        </div>
        </div>
        <div class="mb-3 mt-2">
          <span class="editor-label m-0 p-0">Direction</span>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'BTT'"
              :class="animation.direction == 'BTT' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrow-bar-up"
                font-scale="2"
                animation="cylon-vertical"
              />
              <p class="m-0 mt-2">Rise</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'LTR'"
              :class="animation.direction == 'LTR' ? 'selectedAnimation' : null"
            >
              <b-icon icon="arrow-bar-right" font-scale="2" animation="cylon" />
              <p class="m-0 mt-2">Pan</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'RTL'"
              :class="animation.direction == 'RTL' ? 'selectedAnimation' : null"
            >
              <b-icon icon="arrow-bar-left" font-scale="2" animation="cylon" />
              <p class="m-0 mt-2">Pan</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'TTB'"
              :class="animation.direction == 'TTB' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrow-bar-down"
                font-scale="2"
                animation="cylon-vertical"
              />
              <p class="m-0 mt-2">Rise</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'POP'"
              :class="animation.direction == 'POP' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrows-angle-expand"
                font-scale="2"
                animation="throb"
              />
              <p class="m-0 mt-2">Pop</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'FLIP'"
              :class="
                animation.direction == 'FLIP' ? 'selectedAnimation' : null
              "
            >
              <b-icon
                icon="square-half"
                font-scale="2"
                class="flipX-animation"
              />
              <p class="m-0 mt-2">Flip</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      animation: {},
    };
  },
  computed: {
    ...mapGetters(["getAnimationSetting"]),
    currentAnimationValue() {
      return this.getAnimationSetting;
    },
  },
  watch: {
    animation: {
      deep: true,
      handler(newValue ,oldVal) {
        this.setAnimationValue(this.animation);
        if(Object.keys(oldVal).length != 0 && newValue == oldVal ){
          this.setIsAnyPageEdited(true)
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setAnimationValue","setIsAnyPageEdited"]),
  },

  mounted() {
    if (this.currentAnimationValue) {
      this.animation = this.currentAnimationValue;
    }
  },
};
</script>

<style scoped>
.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  background: transparent;
  border-radius: 4px;
}

/* New Design */

.animationBox {
  padding: 20px 0px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f4f3f5;
  color: #6d6b6d;
  cursor: pointer;
}

.selectedAnimation {
  background: var(--secondary-color);
  color: #fff;
}

@keyframes flipX {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.flipX-animation {
  animation: flipX 2s infinite;
  transform-style: preserve-3d;
}
.progressbar-disabled{
 pointer-events: none;
 opacity: 0.5;
}

</style>
